.landing{
    width:100%;
    min-height: 100vh;
    background-size: cover;
}
.landing-header{
    width:90%;
    padding: 15px 5%;
    display: flex;
    justify-content: center;
    background-color: var(--dark);
    color:white;
    box-shadow: 0px 5px 5px rgba(0,0,0,0.1);
    z-index: 1;
}
.landing-company{
    width:66%;
    padding:30px 17%;
    background-color: white;
    box-shadow: 0px 5px 5px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.landing-reviews{
    width:80%;
    padding:20px 10%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media(max-width: 1000px){

    .landing-company{
        width:94%;
        padding:20px 3%;
    }

    .landing-place{
        display:none;
    }

    .landing-reviews{
        width:100%;
        padding:20px 0%;
        flex-direction: column;
        align-items: center;
    }

}