@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

html{
	
	--font:'Open Sans', 'Helvetica', arial, sans-serif;
	--dark:#0b132b;
	--primary:#23ce6b;
	--secondary:#4059AD;
	--success:#23ce6b;
	--info:#6B9AC4;
	--warning:#F4B942;
	--error:#FF1654;
	--action:slategrey;
	--shadow:0px 2px 2px rgba(0,0,0,0.1);
	
	font-family:var(--font);
	color:var(--dark);
}

html, body{
	margin:0;
}
h1,h2,h3,h4,h5,h6,p{
	margin:0;
	padding:0;
}
a{
	text-decoration:none;
	color:inherit;
}

/*VISIBILITY*/
.hidden{
	display:none;
}

/*LAYOUT*/
.layout-center{
	width:100%;
	min-height:100vh;
	display:flex;
	justify-content: center;
	align-items: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.layout{
	display:flex;
}
.layout-menu{
	min-width:300px;
	background-color:var(--dark);
	color:white;
	display:flex;
	flex-direction:column;
	justify-content:space-between;
}
.layout-menu-top{
	margin:30px 20px;
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.layout-menu-options{
	list-style-type:none;
	padding:0;
	margin:0;
}
.layout-menu-option{
	padding:15px;
	cursor:pointer;
	color:rgba(255,255,255,0.7);
	display:flex;
	align-items: center;
	gap:10px;
}
.layout-menu-option:hover{
	color:rgba(255,255,255,0.9);
}
.layout-menu-option-on{
	background-color:rgba(255,255,255,0.2);
	color:white;
}
.layout-menu-close{
	display:none;
	padding:10px;
}
.layout-menu-close i{
	font-size:1.2em;
}
.layout-page{
	flex:1;
	height:100vh;
	max-height:100vh;
	overflow-y:scroll;
}
.layout-page-content{
	margin:20px;
}
.layout-header{
	display:none;
	flex-direction: column;
	background-color:var(--dark);
	color:white;
	box-shadow: 0px 4px 4px rgba(0,0,0,0.1);
}
.layout-header-top{
	padding:10px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/*FLEX*/
.flex-col{display:flex;flex-direction:column;}
.flex-row{display:flex;flex-direction:row;}
.justify-center{justify-content:center;}
.space-between{justify-content:space-between;}
.space-around{justify-content:center;}
.justify-start{justify-content:flex-start;}
.justify-end{justify-content:flex-end;}
.align-center{align-items:center;}
.align-start{align-items:flex-start;}
.align-end{align-items:flex-end;}
.wrap{flex-wrap:wrap;}
.gap5{gap:5px;}
.gap10{gap:10px;}
.gap15{gap:15px;}
.gap20{gap:20px;}
.gap25{gap:25px;}
.gap30{gap:30px;}
.gap40{gap:40px;}
.gap50{gap:50px;}
.flex1{flex:1;}
.flex2{flex:2;}
.flex3{flex:3;}
.flex4{flex:4;}
.flex5{flex:5;}
.flex6{flex:6;}

/*BOX*/
.box{
	border:solid 1px lightgrey;
	border-radius:5px;
	padding:10px;
}

/*WIDTH*/
.w5{width:5px;}
.w10{width:10px;}
.w15{width:15px;}
.w20{width:20px;}
.w25{width:25px;}
.w30{width:30px;}
.w40{width:40px;}
.w50{width:50px;}
.w60{width:60px;}
.w60{width:60px;}
.w70{width:70px;}
.w80{width:80px;}
.w90{width:90px;}
.w100{width:100px;}
.w150{width:150px;}
.w200{width:200px;}
.w300{width:300px;}
.w400{width:400px;}
.w500{width:500px;}
.w600{width:600px;}

/*HEIGHT*/
.h5{height:5px;}
.h10{height:10px;}
.h15{height:15px;}
.h20{height:20px;}
.h25{height:25px;}
.h30{height:30px;}
.h40{height:40px;}
.h50{height:50px;}
.h60{height:60px;}
.h60{height:60px;}
.h70{height:70px;}
.h80{height:80px;}
.h90{height:90px;}
.h100{height:100px;}
.h150{height:150px;}
.h200{height:200px;}
.h300{height:300px;}
.h400{height:400px;}
.h500{height:500px;}
.h600{height:600px;}

/*SPACING*/
.m-auto{margin:auto;}
.m5{margin:5px;}
.m10{margin:10px;}
.m15{margin:15px;}
.m20{margin:20px;}
.m25{margin:25px;}
.m30{margin:30px;}
.m40{margin:40px;}
.m50{margin:50px;}
.mt5{margin-top:5px;}
.mt10{margin-top:10px;}
.mt15{margin-top:15px;}
.mt20{margin-top:20px;}
.mt25{margin-top:25px;}
.mt30{margin-top:30px;}
.mt40{margin-top:40px;}
.mt50{margin-top:50px;}
.mb5{margin-bottom:5px;}
.mb10{margin-bottom:10px;}
.mb15{margin-bottom:15px;}
.mb20{margin-bottom:20px;}
.mb25{margin-bottom:25px;}
.mb30{margin-bottom:30px;}
.mb40{margin-bottom:40px;}
.mb50{margin-bottom:50px;}
.ml5{margin-left:5px;}
.ml10{margin-left:10px;}
.ml15{margin-left:15px;}
.ml20{margin-left:20px;}
.ml25{margin-left:25px;}
.ml30{margin-left:30px;}
.ml40{margin-left:40px;}
.ml50{margin-left:50px;}
.mr5{margin-right:5px;}
.mr10{margin-right:10px;}
.mr15{margin-right:15px;}
.mr20{margin-right:20px;}
.mr25{margin-right:25px;}
.mr30{margin-right:30px;}
.mr40{margin-right:40px;}
.mr50{margin-right:50px;}
.p5{padding:5px;}
.p10{padding:10px;}
.p15{padding:15px;}
.p20{padding:20px;}
.p25{padding:25px;}
.p30{padding:30px;}
.p40{padding:40px;}
.p50{padding:50px;}
.pt5{padding-top:5px;}
.pt10{padding-top:10px;}
.pt15{padding-top:15px;}
.pt20{padding-top:20px;}
.pt25{padding-top:25px;}
.pt30{padding-top:30px;}
.pt40{padding-top:40px;}
.pt50{padding-top:50px;}
.pb5{padding-bottom:5px;}
.pb10{padding-bottom:10px;}
.pb15{padding-bottom:15px;}
.pb20{padding-bottom:20px;}
.pb25{padding-bottom:25px;}
.pb30{padding-bottom:30px;}
.pb40{padding-bottom:40px;}
.pb50{padding-bottom:50px;}
.pl5{padding-left:5px;}
.pl10{padding-left:10px;}
.pl15{padding-left:15px;}
.pl20{padding-left:20px;}
.pl25{padding-left:25px;}
.pl30{padding-left:30px;}
.pl40{padding-left:40px;}
.pl50{padding-left:50px;}
.pr5{padding-right:5px;}
.pr10{padding-right:10px;}
.pr15{padding-right:15px;}
.pr20{padding-right:20px;}
.pr25{padding-right:25px;}
.pr30{padding-right:30px;}
.pr40{padding-right:40px;}
.pr50{padding-right:50px;}

/*TEXT*/
.center{text-align:center;}
.left{text-align:left;}
.right{text-align:right;}
.underline{text-decoration:underline;}
.small{font-size:0.9em;}
.pointer{cursor:pointer;}

/*FORM*/
.input{
	background:transparent;
	background-color:transparent;
	height:40px;
	border:solid 1px lightgrey;
	border-radius:5px;
	padding:0px 10px;
	font-size:1em;
	min-width:10px;
	font-family:var(--font);
}
.input:focus{
	border:solid 1px var(--dark);
	outline: none;
}
.checkbox{
	width:16px;
	min-width:16px;
	height:16px;
	border:solid 1px;
	border-radius:2px;
	cursor:pointer;
	color:white;
}
.checkbox-off{
	border-color:darkgrey;
	background-color: transparent;
}
.checkbox-on{
	border-color:var(--primary);
	background-color: var(--primary);
}

.radio{
	width:16px;
	min-width:16px;
	height:16px;
	border-radius:50%;
	border:solid 1px;
	cursor:pointer;
	display:flex;
	justify-content: center;
	align-items:center;
}
.radio-off{
	border-color:darkgrey;
}
.radio-on{
	border-color:var(--primary);
}
.radio-bull{
	width:13px;
	height:13px;
	border-radius:50%;
}
.radio-on .radio-bull{
	background-color:var(--primary);
}

.toggle{
	width:32px;
	min-width:32px;
	height:16px;
	border-radius:8px;
	border:solid 1px;
	cursor:pointer;
	display:flex;
	align-items:center;
}
.toggle-off{
	border-color:darkgrey;
	background-color:darkgrey;
}
.toggle-on{
	border-color:var(--primary);
	background-color:var(--primary);
	justify-content: flex-end;
}
.toggle-bull{
	width:16px;
	height:16px;
	border-radius:50%;
	background-color: white;
}

/*MODAL*/
.modal-container{
	position:absolute;
	top:0;left:0;right:0;bottom:0;
	background-color:rgba(0,0,0,0.5);
	display:flex;
	justify-content: center;
	align-items: center;
}
.modal{
	background:white;
	border-radius: 5px;
	min-width:260px;
	padding:20px;
	margin:10px;
}

/*BUTTON*/
.btn{
	height:40px;
	border:none;
	border-radius:5px;
	font-size:1em;
	cursor:pointer;
	background-color:transparent;
	color:white;
	font-family:var(--font);
	display:flex;
	justify-content: center;
	align-items:center;
	text-align:center;
}
.btn:disabled{
	color:darkgrey;
	background-color:rgb(240,240,240);
	cursor:default;
}
.btn:enabled:hover{
	opacity:0.9;
}

/*CARD*/
.card{
	border:solid 1px lightgrey;
	border-radius:5px;
	max-width:300px;
}
.card-img{
	width:100%;
	aspect-ratio:2/1;
	object-fit:cover;
	border-radius:5px 5px 0px 0px;
}
.card-content{
	padding:10px;
}

/*TABLE*/
.table{
	border:solid 1px lightgrey;
	border-radius:5px;
	font-size:0.9em;
	overflow-x:hidden;
}
.table table{
	border-collapse:collapse;
	width:100%;
}
.table th, .table td{
	padding:15px;
	border-bottom:solid 1px lightgrey;
	text-align:left;
}
.table:hover{
	overflow-x:scroll;
}

/*DISPLAY*/
.alert{
	border-left:solid 5px;
	background-color:rgb(245,245,245);
	padding:10px;
	text-align:left;
	display:flex;
	align-items: center;
	gap:10px;
}
.toast{
	color:white;
	padding:10px 20px;
	z-index:999;
	box-shadow:0px 3px 3px rgba(0,0,0,0.2);
	bottom:0;left:50%;
	position:absolute;
	transform: translate(-50%, -50%);
	cursor:default;
}
.avatar{
	min-width:40px;
	aspect-ratio:1/1;
	border-radius:50%;
	display:flex;
	justify-content:center;
	align-items:center;
	color:white;
	font-size:1.1em;
}
.chip{
	padding:5px 10px;
	border-radius:15px;
	width:fit-content;
	font-size:0.9em;
}
.bull{
	min-width:10px;
	aspect-ratio:1/1;
	border-radius:50%;
}

/*ICONS*/
.icon{
	width:24px;
}

/*COLORS*/
.primary{color:var(--primary);}
.secondary{color:var(--secondary);}
.success{color:var(--success);}
.warning{color:var(--warning);}
.error{color:var(--error);}
.info{color:var(--info);}
.action{color:var(--action);}
.dark{color:var(--dark);}
.light{color:grey;}
.bg-primary{background-color:var(--primary);color:white;}
.bg-secondary{background-color:var(--secondary);color:white;}
.bg-success{background-color:var(--success);color:white;}
.bg-warning{background-color:var(--warning);color:white;}
.bg-error{background-color:var(--error);color:white;}
.bg-info{background-color:var(--info);color:white;}
.bg-action{background-color:var(--action);color:white;}
.bg-dark{background-color:var(--dark);color:white;}
.bg-light{background-color:rgb(240,240,240);color:rgb(60,60,60);}
.bd-primary{border-color:var(--primary);}
.bd-secondary{border-color:var(--secondary);}
.bd-success{border-color:var(--success);}
.bd-warning{border-color:var(--warning);}
.bd-error{border-color:var(--error);}
.bd-info{border-color:var(--info);}
.bd-action{border-color:var(--action);}
.bd-dark{border-color:var(--dark);}
.bd-light{border-color:lightgrey;}
.ol-primary{color:var(--primary);border:solid 1px var(--primary);}
.ol-secondary{color:var(--secondary);border:solid 1px var(--secondary);}
.ol-success{color:var(--success);border:solid 1px var(--success);}
.ol-warning{color:var(--warning);border:solid 1px var(--warning);}
.ol-error{color:var(--error);border:solid 1px var(--error);}
.ol-info{color:var(--info);border:solid 1px var(--info);}
.ol-action{color:var(--action);border:solid 1px var(--action);}
.ol-dark{color:var(--dark);border:solid 1px var(--dark);}
.ol-light{color:grey;border:solid 1px grey;}


@media(max-width: 800px){
	
	.layout-menu{
		display:none;
	}
	
	.layout-header{
		display:flex;
	}
	
	.toast{
		bottom:0;
		left:0;
		right:0;
		transform:none;
	}

	.table{
		overflow-x:scroll;
	}
}


/*LOADER*/
.loader{
    width: 30px;
    height: 30px;
	margin:5px;
    border: 4px solid var(--primary);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
} 